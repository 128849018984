import axios from "axios";
import { supabase } from "../supabaseClient";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:5059/api";

// Create an axios instance
const api = axios.create({
  baseURL: API_URL,
});

// Add a request interceptor to include the auth token in every request
api.interceptors.request.use(
  async (config) => {
    const {
      data: { session },
    } = await supabase.auth.getSession();
    if (session?.access_token) {
      config.headers.Authorization = `Bearer ${session.access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export const addCase = async (caseData) => {
  try {
    const response = await api.post("/cases", caseData);
    return response.data;
  } catch (error) {
    console.error("Error adding case:", error);
    throw new Error("Failed to add case");
  }
};

export const fetchCases = async () => {
  try {
    const response = await api.get("/cases");
    return response.data;
  } catch (error) {
    console.error("Error fetching cases:", error);
    throw new Error("Failed to fetch cases");
  }
};

export const fetchCaseDetails = async (id) => {
  try {
    const response = await api.get(`/cases/${id}`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      const notFoundError = new Error("Case not found");
      notFoundError.response = { status: 404 };
      throw notFoundError;
    }

    console.error("Error fetching case details:", error);
    throw new Error("Failed to fetch case details");
  }
};

export const uploadFiles = async (files, case_id) => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append("files", file);
  });
  formData.append("case_id", case_id);

  try {
    const response = await api.post("/lawyer_upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error uploading files:", error);
    throw new Error(error.response.data.error);
  }
};

export const addAnalysis = async (analysisData) => {
  try {
    const response = await api.post("/analysis", analysisData);
    return response.data;
  } catch (error) {
    console.error("Error adding analysis:", error);
    throw new Error(error.response.data.error);
  }
};

export const fetchAnalysis = async (id) => {
  try {
    const response = await api.get(`/analysis/${id}`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      const notFoundError = new Error("Analysis not found");
      notFoundError.response = { status: 404 };
      throw notFoundError;
    }
    console.error("Error fetching analysis:", error);
    throw error;
  }
};

export const deleteAnalysis = async (id) => {
  try {
    const response = await api.delete(`/analysis/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting analysis:", error);
    throw error;
  }
};

export const fetchAnalysisTypes = async () => {
  try {
    const response = await api.get("/analysis-types");
    return response.data;
  } catch (error) {
    console.error("Error fetching analysis types:", error);
    throw new Error("Failed to fetch analysis types");
  }
};

export const fetchPdfUrl = async (fileId) => {
  try {
    const response = await api.get(`file/${fileId}`, {
      responseType: "blob",
    });
    console.log("resp", response);
    const blob = new Blob([response.data], { type: "application/pdf" });
    console.log("blob", blob);
    const url = URL.createObjectURL(blob);
    console.log("url", url);
    return url;
  } catch (error) {
    console.error("Failed to fetch PDF:", error);
    throw new Error("Failed to fetch PDF");
  }
};

export const downloadFile = async (fileIdOrPath) => {
  try {
    const response = await api.get(`file/${fileIdOrPath}`, {
      responseType: "blob",
    });

    // Determine the MIME type based on file extension
    let mimeType = "application/octet-stream"; // default fallback
    if (fileIdOrPath.endsWith(".pdf")) {
      mimeType = "application/pdf";
    } else if (fileIdOrPath.endsWith(".docx")) {
      mimeType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    }
    
    // Create blob with determined MIME type
    const blob = new Blob([response.data], { type: mimeType });
    const url = URL.createObjectURL(blob);
    
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;

    // Try to get filename from Content-Disposition header first
    const contentDisposition = response.headers["content-disposition"];
    const filename = contentDisposition
      ? contentDisposition.split("filename=")[1].replace(/"/g, "")
      : fileIdOrPath.includes("/") 
        ? fileIdOrPath.split("/").pop() // Use last part of path if it's a path
        : `file-${fileIdOrPath}`; // Fallback for simple IDs
    
    a.download = filename;
    
    // Add to DOM, click, and clean up
    document.body.appendChild(a);
    a.click();
    
    // Cleanup
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  } catch (error) {
    console.error("Error downloading file:", error);
    throw new Error("Failed to download file");
  }
};


export const deleteFile = async (fileId) => {
  try {
    const response = await api.delete(`/file/${fileId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting file:", error);
    if (
      error.response &&
      error.response.status === 500 &&
      error.response.data &&
      error.response.data.error
    ) {
      if (error.response.data.error.includes("foreign key constraint")) {
        throw new Error(
          "Couldn't delete file because evidence reports exist based on it. You need to delete those first.",
        );
      } else if (error.response.data.error.includes("RLS")) {
        throw new Error(
          "Couldn't delete file because you don't have permission.",
        );
      }
    }
    throw new Error("Failed to delete file");
  }
};

export const fetchIntakeQuestions = async () => {
  try {
    const { data, error } = await supabase
      .from("intake_questions")
      .select("*")
      .order("id", { ascending: true });

    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error fetching intake questions:", error);
    throw new Error("Failed to fetch intake questions");
  }
};

export const updateCaseIntakeForm = async (caseId, formData) => {
  try {
    const { data, error } = await supabase
      .from("cases")
      .update({ intake_form: formData })
      .eq("id", caseId);

    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error updating case intake form:", error);
    throw new Error("Failed to update case intake form");
  }
};

export const generatePublicLink = async (caseId) => {
  try {
    const response = await axios.post(
      `${API_URL}/cases/${caseId}/public-link`,
      {},
      {
        headers: {
          Authorization: `Bearer ${(await supabase.auth.getSession()).data.session?.access_token}`,
        },
      },
    );

    if (response.data && response.data.publicUrl) {
      return response.data.publicUrl;
    } else {
      throw new Error("Invalid response format");
    }
  } catch (error) {
    console.error("Error generating public link:", error);
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error("Server responded with:", error.response.data);
      console.error("Status code:", error.response.status);
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("Error setting up request:", error.message);
    }
    throw new Error("Failed to generate public link");
  }
};

export const fetchPublicIntakeForm = async (id) => {
  try {
    console.log("Fetching public intake form for id:", id);
    const response = await axios.get(`${API_URL}/public-intake/${id}`);
    console.log("Received response:", response.data);

    if (response.data && response.data.case && response.data.questions) {
      return response.data;
    } else {
      console.error("Unexpected response structure:", response.data);
      throw new Error("Invalid response format");
    }
  } catch (error) {
    console.error("Error fetching public intake form:", error);
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      console.error("Status code:", error.response.status);
    } else if (error.request) {
      console.error("No response received:", error.request);
    } else {
      console.error("Error setting up request:", error.message);
    }
    throw new Error("Failed to fetch public intake form");
  }
};

export const submitPublicIntakeForm = async (id, formData) => {
  try {
    console.log("Submitting public intake form for id:", id);
    const response = await axios.post(
      `${API_URL}/public-intake/${id}`,
      formData,
    );
    console.log("Received response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error submitting public intake form:", error);
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      console.error("Status code:", error.response.status);
    } else if (error.request) {
      console.error("No response received:", error.request);
    } else {
      console.error("Error setting up request:", error.message);
    }
    throw new Error("Failed to submit public intake form");
  }
};

export const updateCase = async (id, caseData) => {
  try {
    const { data, error } = await supabase
      .from("cases")
      .update(caseData)
      .eq("id", id)
      .single();

    if (error) {
      if (error.code === "PGRST116") {
        throw new Error("You don't have permission to edit this case.");
      }
      throw error;
    }

    return data;
  } catch (error) {
    console.error("Error updating case:", error);
    throw error; // Throw the error to be handled by the component
  }
};

// New section: Patient Flow API functions
// =======================================

export const uploadFilesUnauthenticated = async (files, case_id) => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append("files", file);
  });
  formData.append("case_id", case_id);

  try {
    const response = await axios.post(`${API_URL}/public/patient_upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error uploading files:", error);
    throw new Error(error.response?.data?.error || "Failed to upload files");
  }
};

async function getClientIP() {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error('Error fetching IP:', error);
    return null;
  }
};

export const patientFlowAPI = {
  // Step 1: Save Personal Information
  savePersonalInfo: async (personalInfo) => {
    try {
      const clientIP = await getClientIP();
      const { data, error } = await supabase
        .from('patient_cases')
        .insert([{
          first_name: personalInfo.name.split(' ')[0],
          last_name: personalInfo.name.split(' ').slice(1).join(' '),
          email: personalInfo.email,
          status: 'pending',
          ip: clientIP
        }])
        .select()
        .single();

      if (error) throw error;

      if (!data) {
        throw new Error('No data returned from server');
      }

      return data;
    } catch (error) {
      console.error('Error in savePersonalInfo:', error);
      throw error; // Re-throw the error to be caught in the component
    }
  },
  // Step 2: Update Case Details
  updateCaseDetails: async (caseId, caseDetails) => {
    try {
      const { data, error } = await supabase
        .from('patient_cases')
        .update({
          birthday: caseDetails.birthday,
          state: caseDetails.state,
          condition: caseDetails.condition,
          incident_date: caseDetails.incidentDate,
          impact: caseDetails.impact,
        })
        .eq('id', caseId)
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Error updating case details:', error);
      throw new Error('Failed to update case details');
    }
  },

  // Step 3: File Upload (using existing uploadFiles function)
  uploadFiles: uploadFilesUnauthenticated,

  // Step 4: Confirm Case
  confirmCase: async (caseId) => {
    try {
      const { data, error } = await supabase
        .from('patient_cases')
        .update({ status: 'confirmed' })
        .eq('id', caseId)
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Error confirming case:', error);
      throw new Error('Failed to confirm case');
    }
  },

  // Step 5: Get Case Results
  getCaseResults: async (caseId) => {
    try {
      const { data, error } = await supabase
        .from('patient_cases')
        .select('*, patient_files(*)')
        .eq('id', caseId)
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Error fetching case results:', error);
      throw new Error('Failed to fetch case results');
    }
  },

  // Helper function to handle the entire flow
  handlePatientFlow: async (flowData) => {
    try {
      const newCase = await patientFlowAPI.savePersonalInfo(flowData.personalInfo);
      await patientFlowAPI.updateCaseDetails(newCase.id, flowData.caseDetails);
      
      if (flowData.files && flowData.files.length > 0) {
        await patientFlowAPI.uploadFiles(flowData.files, newCase.id);
      }
      
      await patientFlowAPI.confirmCase(newCase.id);
      const results = await patientFlowAPI.getCaseResults(newCase.id);
      
      return results;
    } catch (error) {
      console.error('Error in patient flow:', error);
      throw new Error('Failed to complete patient flow');
    }
  }
};

export const trackEvent = async (eventName, pathname) => {
  try {
    const clientIP = await getClientIP();  // Use your existing function
    
    const response = await api.post('/public/tr', {
      eventName,
      pathname,
      clientIP  // Pass the IP in the request body
    });
    return response.data;
  } catch (error) {
    console.error('Error tracking event:', error);
    // Don't throw to prevent breaking the user flow
  }
};

