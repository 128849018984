import React, { useState, useMemo, useEffect } from "react";
import { Search, ChevronDown, ChevronUp, Loader } from "lucide-react";
import CollapsiblePDFDisplay from "../../components/CollapsiblePDFDisplay";

export const Alert = ({ children, className }) => (
  <div className={`p-4 rounded-md ${className}`}>{children}</div>
);

export const AlertTitle = ({ children, className }) => (
  <h3 className={`text-lg font-semibold mb-2 ${className}`}>{children}</h3>
);

export const ChunkContent = ({ children, className }) => (
  <div className={`bg-white p-4 mb-4 rounded-md shadow-sm ${className}`}>
    {children}
  </div>
);

const SharedAnalysisDisplay = ({
  content,
  pdfUrl,
  pagesPerChunk,
  alertClassName,
  titleClassName,
  renderContent,
}) => {
  const [filterText, setFilterText] = useState("");
  const [expandedSections, setExpandedSections] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [shouldUseDropdown, setShouldUseDropdown] = useState(false);

  const filteredContent = useMemo(() => {
    if (!filterText) return content;
    return Object.fromEntries(
      Object.entries(content).filter(([key, data]) => {
        return (
          data.chunks &&
          data.chunks.some((chunk) =>
            JSON.stringify(chunk)
              .toLowerCase()
              .includes(filterText.toLowerCase()),
          )
        );
      }),
    );
  }, [content, filterText]);

  const sortedFilteredContent = useMemo(() => {
    return Object.entries(filteredContent).sort(
      ([, a], [, b]) => (b.chunk_ids?.length || 0) - (a.chunk_ids?.length || 0),
    );
  }, [filteredContent]);

  useEffect(() => {
    if (sortedFilteredContent.length > 0) {
      const firstElementChunks = sortedFilteredContent[0][1]?.chunks;
      setShouldUseDropdown(firstElementChunks && firstElementChunks.length > 5);
      setIsLoading(false);
    }
  }, [sortedFilteredContent]);

  const toggleSection = (key) => {
    setExpandedSections((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader className="animate-spin" size={48} />
      </div>
    );
  }

  const renderChunks = (key, data) => (
    <div className="mt-4">
      {data.chunks.map((chunk, index) => {
        // Only render chunks that match the filter text when filtering
        if (
          filterText &&
          !JSON.stringify(chunk)
            .toLowerCase()
            .includes(filterText.toLowerCase())
        ) {
          return null;
        }

        return (
          <div
            key={index}
            className="mt-3 bg-gray-50 p-4 rounded-lg"
            data-conv-id={data.conv_ids?.[index]}
          >
            {renderContent(key, data, index)}
            <CollapsiblePDFDisplay
              chunkId={data.chunk_ids[index]}
              pdfUrl={pdfUrl}
              pagesPerChunk={pagesPerChunk}
              chunkIndex={index}
              searchTerm={filterText}
              alertTerms={data.excerpts?.[index] || {}}
            />
          </div>
        );
      })}
    </div>
  );

  return (
    <div className="space-y-6">
      <div className="relative">
        <input
          type="text"
          placeholder="Only show sections that contain this text..."
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          className="w-full px-4 py-2 pl-10 pr-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <Search
          className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
          size={20}
        />
      </div>
      {sortedFilteredContent.map(([key, data]) => (
        <Alert key={key} className={alertClassName}>
          <AlertTitle className={titleClassName}>{key}</AlertTitle>
          <div className="mt-2 space-y-4">
            {renderContent(key, data)}
            {shouldUseDropdown ? (
              <div>
                <button
                  onClick={() => toggleSection(key)}
                  className="flex items-center text-blue-600 hover:text-blue-800"
                >
                  {expandedSections[key] ? (
                    <>
                      <ChevronUp className="mr-2" size={20} />
                      Hide Sections
                    </>
                  ) : (
                    <>
                      <ChevronDown className="mr-2" size={20} />
                      Show Sections ({data.chunks.length})
                    </>
                  )}
                </button>
                {expandedSections[key] && renderChunks(key, data)}
              </div>
            ) : (
              renderChunks(key, data)
            )}
          </div>
        </Alert>
      ))}
    </div>
  );
};

export default SharedAnalysisDisplay;
