import React from "react";
import { deleteAnalysis } from "../../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faClock,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";

export const EvidenceSection = ({
  analyses,
  analysisTypes,
  refreshAnalyses,
}) => {
  const handleDelete = async (analysisId) => {
    if (window.confirm("Are you sure you want to delete this analysis?")) {
      try {
        await deleteAnalysis(analysisId);
        refreshAnalyses();
      } catch (error) {
        console.error("Failed to delete analysis:", error);
        alert("Failed to delete analysis. Please try again.");
      }
    }
  };

  return (
    <>
      {analyses.length > 0 && (
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-3xl font-bold mb-4">Evidence</h2>
          <section className="mb-8">
            <div className="space-y-4">
              {analyses.map((analysis) => (
                <div
                  key={analysis.id}
                  className="bg-white shadow rounded-lg p-6" data-id={analysis.id}
                >
                  <div className="flex flex-col sm:flex-row justify-between items-start">
                    <div className="space-y-2 flex-grow w-full sm:w-auto mb-4 sm:mb-0">
                      <div className="flex items-center text-lg font-semibold text-black mr-1">
                        <FontAwesomeIcon
                          icon={faFileAlt}
                          className="text-blue-500 mr-2"
                        />
                        {analysisTypes.find((type) => type.id === analysis.type)
                          ?.display_name || "Unknown"}
                      </div>
                      <p className="font-medium text-gray-900">
                        {Object.entries(analysis.parameters || {}).map(
                          ([key, value], index, array) => (
                            <span key={key}>
                              <span className="font-normal">{key}</span>:{" "}
                              <strong>{value}</strong>
                              {index < array.length - 1 && ", "}
                            </span>
                          ),
                        )}
                      </p>
                      <p>
                        File: <strong>{analysis.file_name}</strong>
                      </p>
                      {analysis.status === "complete" && analysis.content && (
                        <p>
                          <strong>
                            {Object.keys(analysis.content).length}
                          </strong>{" "}
                          areas with evidence found
                        </p>
                      )}
                      <p className="flex items-center text-gray-500">
                        <FontAwesomeIcon icon={faClock} className="mr-2" />
                        {new Date(analysis.created_at).toLocaleString()}
                      </p>
                    </div>
                    <div className="flex flex-row sm:flex-col space-x-2 sm:space-x-0 sm:space-y-2 w-full sm:w-auto">
                      {analysis.status === "complete" && analysis.content && (
                        <a
                          href={`../analysis/${analysis.id}`}
                          className="flex-1 sm:flex-none px-6 py-2 bg-blue-500 text-white text-center rounded-md hover:bg-blue-600 transition-colors duration-300"
                        >
                          View
                        </a>
                      )}
                      {analysis.status !== "complete" && (
                        <div className="inline-flex items-center bg-yellow-100 text-yellow-800 px-2 py-1 rounded-full text-sm">
                          <FontAwesomeIcon
                            icon={faSpinner}
                            className="mr-1 animate-spin"
                          />
                          Processing
                        </div>
                      )}
                      <button
                        onClick={() => handleDelete(analysis.id)}
                        className="flex-1 sm:flex-none px-6 py-2 bg-red-500 text-white text-center rounded-md hover:bg-red-600 transition-colors duration-300"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      )}
    </>
  );
};
