import React, { useState } from "react";
import { uploadFiles } from "../services/api";

const UploadForm = ({ onUploadSuccess, onUploadError, case_id }) => {
  const [files, setFiles] = useState([]);

  const handleFileChange = (e) => {
    setFiles(Array.from(e.target.files));
  };

  const handleSubmit = async () => {
    try {
      await uploadFiles(files, case_id);
      console.log("Files uploaded successfully");
      // Clear the file list after successful upload
      setFiles([]);

      if (onUploadSuccess) {
        onUploadSuccess();
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      if (onUploadError) {
        onUploadError(error.message || "An error occured during file upload.");
      }
    }
  };

  return (
    <div className="flex flex-col items-center space-y-4">
      <label htmlFor="upload-files" className="cursor-pointer">
        <div className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center">
          <svg
            className="w-6 h-6 mr-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
            />
          </svg>
          Choose Files
        </div>
      </label>
      <input
        id="upload-files"
        type="file"
        multiple
        onChange={handleFileChange}
        className="hidden"
      />

      {files.length > 0 && (
        <div className="w-full mt-4">
          <h3 className="text-lg font-semibold mb-2">Selected Files:</h3>
          <ul className="list-disc pl-5">
            {files.map((file, index) => (
              <li key={index} className="text-sm text-gray-600">
                {file.name} ({(file.size / 1024).toFixed(2)} KB)
              </li>
            ))}
          </ul>
        </div>
      )}

      <button
        onClick={handleSubmit}
        disabled={files.length === 0}
        className={`bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
          files.length === 0
            ? "opacity-50 cursor-not-allowed"
            : "hover:bg-blue-700"
        }`}
      >
        Upload
      </button>
    </div>
  );
};

export default UploadForm;
