import React from "react";
import { useNavigate } from "react-router-dom";
import { AlertTriangle, Activity, DollarSign } from "lucide-react";
import { patientFlowAPI, trackEvent } from '../../services/api';

const LandingPage = ({ session }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    trackEvent('ViewContent', '/')
        .catch(error => console.error('Error tracking ViewContent:', error));
    
    navigate("/step1");
  };

  const facts = [
    {
      icon: <AlertTriangle className="text-red-500" size={36} />,
      number: "1.1 Million",
      description: "Patients in the US are disabled or die yearly due to diagnostic errors",
      source: "Source: 2024 BMJ Quality & Safety study"
    },
    {
      icon: <Activity className="text-blue-500" size={36} />,
      number: "7.4 Million",
      description: "Misdiagnosis errors are made every year in US emergency rooms alone",
      source: "Source: US Dept. of Health and Human Services"
    },
    {
      icon: <DollarSign className="text-green-500" size={36} />,
      number: "$40+ Billion",
      description: "In damages paid because of medical negligence every year",
      source: "Source: Health Resources and Services Administration"
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
      <div className="container mx-auto px-4 py-8 max-w-7xl">
        <div className="text-center mb-8">
          <h1 className="text-5xl font-bold mb-4 text-gray-800 leading-tight">
            Think Your Doctors 
            <br/>
            Made a Mistake?
          </h1>
          <p className="text-2xl text-gray-600 mb-4">
            Find out if you were <b>misdiagnosed</b> or didn't get the treatment you deserved.
            <br/> 
            Your first case review is <b>free</b>, until January 1st.
          </p>
        </div>
        
        <div className="text-center mb-12">
          <button
            onClick={handleButtonClick}
            className="bg-green-500 hover:bg-green-600 text-white font-semibold py-3 px-8 rounded-full text-lg transition duration-300 ease-in-out shadow-md"
          >
            Ask for a Case Review 
          </button>
        </div>

        {/* Desktop Layout */}
        <div className="hidden md:flex md:flex-row md:gap-8 mb-12 justify-center">
          {/* Left fact */}
          <div className="w-1/4">
            <div className="bg-white rounded-xl shadow-lg p-8 flex flex-col justify-center h-full border border-gray-100">
              <div className="text-red-500 mb-6">
                <AlertTriangle size={36} />
              </div>
              <h3 className="text-2xl font-bold text-gray-800 mb-4">
                1.1 Million
              </h3>
              <p className="text-lg text-gray-600 leading-relaxed">
                Patients in the US are disabled or die yearly due to diagnostic errors
              </p>
              <p className="text-sm text-gray-500 mt-4">
                Source: 2024 BMJ Quality & Safety study
              </p>
            </div>
          </div>
          
          {/* Video section */}
          <div className="w-2/4">
            <iframe 
              className="w-full aspect-video rounded-xl shadow-lg" 
              src="https://www.youtube.com/embed/0vUuGpK3T8A?si=R1VV7t6nCiqpTLro&autoplay=1" 
              title="YouTube video player" 
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              referrerPolicy="strict-origin-when-cross-origin" 
              allowFullScreen
            ></iframe>
          </div>

          {/* Right fact */}
          <div className="w-1/4">
            <div className="bg-white rounded-xl shadow-lg p-8 flex flex-col justify-center h-full border border-gray-100">
              <div className="text-blue-500 mb-6">
                <Activity size={36} />
              </div>
              <h3 className="text-2xl font-bold text-gray-800 mb-4">
                7.4 Million
              </h3>
              <p className="text-lg text-gray-600 leading-relaxed">
                Misdiagnosis errors are made every year in US emergency rooms alone
              </p>
              <p className="text-sm text-gray-500 mt-4">
                Source: US Dept. of Health and Human Services
              </p>
            </div>
          </div>
        </div>

        {/* Mobile Video */}
        <div className="md:hidden mb-8">
          <iframe 
            className="w-full aspect-video rounded-xl shadow-lg" 
            src="https://www.youtube.com/embed/0vUuGpK3T8A?si=R1VV7t6nCiqpTLro&autoplay=1" 
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerPolicy="strict-origin-when-cross-origin" 
            allowFullScreen
          ></iframe>
        </div>

        {/* Mobile Facts */}
        <div className="md:hidden space-y-4 mb-12">
          {facts.map((fact, index) => (
            <div key={index} className="bg-white rounded-xl shadow-lg px-6 py-4 flex items-center justify-between border border-gray-100 flex-wrap gap-4">
              <div className="flex items-center flex-1 min-w-[280px]">
                <div className="mr-4">
                  {fact.icon}
                </div>
                <div>
                  <h3 className="text-xl font-bold text-gray-800">
                    {fact.number}
                  </h3>
                  <p className="text-gray-600">
                    {fact.description}
                  </p>
                </div>
              </div>
              <p className="text-sm text-gray-500 w-full md:w-auto">
                {fact.source}
              </p>
            </div>
          ))}
        </div>

        <div className="text-center mb-12">
          <button
            onClick={handleButtonClick}
            className="bg-green-500 hover:bg-green-600 text-white font-semibold py-4 px-10 rounded-full text-lg transition duration-300 ease-in-out shadow-md"
          >
           Ask for a Case Review 
          </button>
        </div>
        
        {/* Desktop Third Fact */}
        <div className="hidden md:block max-w-3xl mx-auto mb-12">
          <div className="bg-white rounded-xl shadow-lg px-8 py-4 flex items-center justify-between border border-gray-100 flex-wrap gap-4">
            <div className="flex items-center">
              <div className="text-green-500 mr-4">
                <DollarSign size={36} />
              </div>
              <div>
                <h3 className="text-2xl font-bold text-gray-800">
                  $40+ Billion
                </h3>
                <p className="text-gray-600">
                  In damages paid because of medical negligence every year
                </p>
              </div>
            </div>
            <p className="text-sm text-gray-500">
              Source: Health Resources and Services Administration
            </p>
          </div>
        </div>

        <div className="bg-white rounded-xl shadow-lg p-8 mb-12 max-w-4xl mx-auto border border-gray-100">
          <p className="text-lg text-gray-700 leading-relaxed">
            <span className="text-2xl font-semibold text-gray-800 block mb-4">About Us</span>
            <span className="block mb-3">
              We're patients, <a href="https://hls.harvard.edu/">advocates</a>, and <a href="https://www.roivant.com/">healthcare researchers</a> who wanted to make it easy for people to find out
              if something went wrong with their care and get peace of mind with a little effort as possible.
            </span>
            <span className="block mb-3">
              We believe the only way to fix healthcare is to give patients the tools to check their doctor's work and
              empower them to make things right as quickly, easily, and privately as possible.
            </span>
            <span className="block">
              Please get in touch if you are seeking clarity or confidence about your healthcare.
            </span>
          </p>
        </div>       
 
        <div className="text-center mb-6">
          <button
            onClick={handleButtonClick}
            className="bg-green-500 hover:bg-green-600 text-white font-semibold py-4 px-10 rounded-full text-lg transition duration-300 ease-in-out shadow-md"
          >
            Start Your Free Case Review Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
