import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { supabase } from "./supabaseClient";
import LawyerLandingPage from "./pages/landing/Lawyers/LandingPage";
import Home from "./pages/Home";
import Cases from "./pages/Cases";
import CaseDetails from "./pages/CaseDetails/CaseDetails";
import CaseForm from "./pages/CaseForm";
import Analysis from "./pages/analysis/Analysis";
import Auth from "./components/Auth";
import AuthCallback from "./components/AuthCallback";
import NotFound from "./pages/NotFound";
import Navigation from "./components/Navigation";

// Layout component for lawyer routes
export const LawyerLayout = ({ children, session }) => {
  return (
    <div className="bg-slate-50">
      <Navigation session={session} />
      <div className="container max-w-full pb-4">
        {children}
      </div>
    </div>
  );
};

// Protected route component
export const PrivateRoute = ({ children }) => {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let mounted = true;

    async function getSession() {
      const {
        data: { session },
        error,
      } = await supabase.auth.getSession();
      if (mounted) {
        if (error) {
          console.error("Error fetching session:", error);
        } else {
          setSession(session);
        }
        setLoading(false);
      }
    }

    getSession();

    const { data: authListener } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        if (mounted) {
          setSession(session);
          setLoading(false);
        }
      },
    );

    return () => {
      mounted = false;
      if (authListener && authListener.subscription) {
        authListener.subscription.unsubscribe();
      }
    };
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!session) {
    return <Navigate to="/lawyers/auth" replace />;
  }

  return children;
};

const LawyerRoutes = ({ session }) => {
  return (
    <LawyerLayout session={session}>
      <Routes>
        <Route path="/" element={<LawyerLandingPage session={session} />} />
        <Route
          path="/auth"
          element={!session ? <Auth /> : <Navigate to="/lawyers/home" replace />}
        />
        <Route
          path="/home"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
        <Route
          path="/cases"
          element={
            <PrivateRoute>
              <Cases />
            </PrivateRoute>
          }
        />
        <Route
          path="/cases/:id"
          element={
            <PrivateRoute>
              <CaseDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-case"
          element={
            <PrivateRoute>
              <CaseForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/analysis/:id"
          element={
            <PrivateRoute>
              <Analysis />
            </PrivateRoute>
          }
        />
        <Route
          path="*"
          element={
            <PrivateRoute>
              <NotFound />
            </PrivateRoute>
          }
        />
        <Route path="/auth/callback" element={<AuthCallback />} />
      </Routes>
    </LawyerLayout>
  );
};

export default LawyerRoutes;
