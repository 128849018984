import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PatientUploadForm from './PatientUploadForm';
import { patientFlowAPI, trackEvent } from '../../services/api';
import { Info, Upload, AlertTriangle, HelpCircle, Check, PlayCircle, Shield, FileText, UserCheck } from 'lucide-react';

// Custom Alert component (unchanged)
const Alert = ({ children, variant = 'default' }) => {
  const bgColor = variant === 'destructive' ? 'bg-red-100' : 'bg-blue-100';
  const textColor = variant === 'destructive' ? 'text-red-800' : 'text-blue-800';
  
  return (
    <div className={`${bgColor} ${textColor} p-4 rounded-md mb-4`}>
      {children}
    </div>
  );
};

const Step3RecordUpload = ({ caseId, onComplete }) => {
  // ... (previous state and handlers remain the same)
  const [isUploading, setIsUploading] = useState(false);
  const [localCaseId, setLocalCaseId] = useState(caseId);
  const [error, setError] = useState(null);
  const [showVideo, setShowVideo] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!localCaseId) {
      const storedCaseId = localStorage.getItem('caseId');
      if (storedCaseId) {
        setLocalCaseId(storedCaseId);
      } else {
        console.error('No case ID found in localStorage');
        navigate('/step1');
      }
    }
  }, [localCaseId, navigate]);

  const handleUploadSuccess = () => {
    trackEvent('CompleteRegistration', '/step3')
        .catch(error => console.error('Error tracking CompleteRegistration:', error));
    
    setIsUploading(false);
    onComplete();
  };

  const handleUploadError = (errorMessage) => {
    setIsUploading(false);
    setError(`Failed to upload files: ${errorMessage}`);
  };

  if (!localCaseId) {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-100 to-white py-12">
      <div className="container mx-auto px-4 max-w-3xl">
        <h1 className="text-4xl font-bold mb-6 text-gray-800 text-center">Medical Record Review</h1>
        <p className="text-xl text-center text-gray-600 mb-8">
          We'll analyze your medical records to identify potential misdiagnoses or treatment gaps
        </p>

        <div className="bg-white shadow-lg rounded-xl p-8 mb-8">
          {/* What We Do Section */}
          <div className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">What We'll Do For You:</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex items-start">
                <div className="bg-blue-100 p-3 rounded-full mr-4 flex-shrink-0">
                  <FileText className="h-8 w-8 text-blue-600" />
                </div>
                <div>
                  <h3 className="font-semibold">Expert Analysis</h3>
                  <p className="text-gray-600">Review your records for potential misdiagnoses or missed treatments</p>
                </div>
              </div>
              <div className="flex items-start">
                <div className="bg-green-100 p-3 rounded-full mr-4 flex-shrink-0">
                  <Check className="h-8 w-8 text-green-600" />
                </div>
                <div>
                  <h3 className="font-semibold">Detailed Report</h3>
                  <p className="text-gray-600">Provide clear findings and recommended next steps</p>
                </div>
              </div>
            </div>
          </div>

          {/* Trust & Security Section */}
          <div className="bg-gray-50 rounded-lg p-6 mb-8">
            <h3 className="text-xl font-semibold text-gray-800 mb-4 flex items-center">
              <Shield className="h-8 w-8 text-blue-500 mr-3 flex-shrink-0" />
              Your Privacy & Security
            </h3>
            <div className="space-y-4">
              <div className="flex items-center">
                <Check className="h-7 w-7 text-green-500 mr-3 flex-shrink-0" />
                <span className="text-lg">HIPAA-compliant secure storage</span>
              </div>
              <div className="flex items-center">
                <Check className="h-7 w-7 text-green-500 mr-3 flex-shrink-0" />
                <span className="text-lg">Records are only accessible to our medical review team</span>
              </div>
              <div className="flex items-center">
                <Check className="h-7 w-7 text-green-500 mr-3 flex-shrink-0" />
                <span className="text-lg">Your information is never shared without your explicit permission</span>
              </div>
            </div>
          </div>

          {/* Record Access Help */}
          <div className="bg-gray-50 rounded-lg p-6 mb-6">
            <h3 className="text-xl font-semibold text-gray-800 mb-4 flex items-center">
              <HelpCircle className="h-8 w-8 text-blue-500 mr-3 flex-shrink-0" />
              Need help getting your records?
            </h3>
            <p className="mb-4 text-gray-600 text-lg">
              Most hospitals provide easy access through their patient portal. Watch our quick guide to learn how:
            </p>
            <button
              onClick={() => setShowVideo(!showVideo)}
              className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <PlayCircle className="h-6 w-6 mr-2 flex-shrink-0" />
              {showVideo ? "Hide Guide" : "Watch 2-Minute Guide"}
            </button>
          
            {showVideo && (
              <div className="mt-4">
                <div className="relative w-full" style={{ paddingBottom: '56.25%' }}>
                  <iframe
                    src="https://www.youtube.com/embed/bJ3CDvAS_6g"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="absolute top-0 left-0 w-full h-full rounded-lg"
                  ></iframe>
                </div>
              </div>
            )}
          </div>
          
          {/* Upload Section */}
          <div className="border-t pt-6">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">Upload Your Records</h3>
            <p className="text-gray-600 mb-6 text-lg">
              Ready to get started? Upload your medical records below and we'll begin our review.
            </p>
            
            <PatientUploadForm
              onUploadStart={() => setIsUploading(true)}
              onUploadSuccess={handleUploadSuccess}
              onUploadError={handleUploadError}
              caseId={localCaseId}
            />
          </div>
          
          {isUploading && (
            <div className="mt-4 text-center">
              <div className="inline-flex items-center px-4 py-2 font-semibold leading-6 text-base shadow rounded-md text-white bg-blue-500 transition ease-in-out duration-150 cursor-not-allowed">
                <svg className="animate-spin -ml-1 mr-3 h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Uploading...
              </div>
            </div>
          )}
          
          {error && (
            <Alert variant="destructive">
              <div className="flex items-center">
                <AlertTriangle className="h-6 w-6 mr-2 flex-shrink-0" />
                <span className="font-semibold">Error</span>
              </div>
              <p className="mt-2">{error}</p>
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
};

export default Step3RecordUpload;
