import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { supabase } from "./supabaseClient";
import { pdfjs } from "react-pdf";
import PatientFlow from "./pages/patient_flow/PatientFlow";
import LawyerRoutes from "./LawyerRoutes";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const App = () => {
  const [session, setSession] = useState(null);

  useEffect(() => {
    let mounted = true;

    async function getSession() {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (mounted) {
        setSession(session);
      }
    }

    getSession();

    const { data: authListener } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        if (mounted) {
          setSession(session);
        }
      },
    );

    return () => {
      mounted = false;
      if (authListener && authListener.subscription) {
        authListener.subscription.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    const pixelCode = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '534947692797293');
      fbq('track', 'PageView');
    `;

    const script = document.createElement('script');
    script.textContent = pixelCode;
    document.head.appendChild(script);

  }, []);

  return (
    <Router>
      <Routes>
        {/* Patient routes at root level */}
        <Route path="/*" element={<PatientFlow />} />
        
        {/* Lawyer routes under /lawyers */}
        <Route path="/lawyers/*" element={<LawyerRoutes session={session} />} />
      </Routes>
    </Router>
  );
};

export default App;
