// useAnalysis.js
import { useState, useEffect } from "react";
import { fetchAnalysis, fetchAnalysisTypes } from "../../services/api";

export const useAnalysis = (id) => {
  const [analysis, setAnalysis] = useState(null);
  const [analysisTypes, setAnalysisTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [analysisData, types] = await Promise.all([
          fetchAnalysis(id),
          fetchAnalysisTypes(),
        ]);
        setAnalysis(analysisData);
        setAnalysisTypes(types);
        setError(null);
      } catch (error) {
        console.error("Error fetching analysis:", error);
        setError(error.response?.status === 404 ? "not_found" : "error");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  return { analysis, analysisTypes, loading, error };
};
