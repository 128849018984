import React, { useState, useEffect } from 'react';

const getDateParts = (dateString) => {
  if (!dateString) return { month: '', day: '', year: '' };
  const [year, month, day] = dateString.split('-');
  return { month, day, year };
};

const DateDropdownSelector = ({ formData, handleChange }) => {
  const [selectedDate, setSelectedDate] = useState(getDateParts(formData.incidentDate));

  const months = [
    { value: '01', label: 'January' },
    { value: '02', label: 'February' },
    { value: '03', label: 'March' },
    { value: '04', label: 'April' },
    { value: '05', label: 'May' },
    { value: '06', label: 'June' },
    { value: '07', label: 'July' },
    { value: '08', label: 'August' },
    { value: '09', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' }
  ];

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 10 }, (_, i) => 
    (currentYear - 5 + i).toString()
  );

  const getDaysInMonth = (month, year) => {
    if (!month || !year) return 31;
    return new Date(year, month, 0).getDate();
  };

  const days = Array.from(
    { length: getDaysInMonth(parseInt(selectedDate.month), parseInt(selectedDate.year)) },
    (_, i) => (i + 1).toString().padStart(2, '0')
  );

  const updateParentForm = (newDate) => {
    if (newDate.year && newDate.month && newDate.day) {
      const dateStr = `${newDate.year}-${newDate.month}-${newDate.day}`;
      handleChange({
        target: {
          name: 'incidentDate',
          value: dateStr
        }
      });
    }
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    
    let newSelectedDate = { ...selectedDate };
    
    if (name === 'year') {
      newSelectedDate = { ...selectedDate, year: value, month: '', day: '' };
    } else if (name === 'month') {
      newSelectedDate = { ...selectedDate, month: value, day: '' };
    } else {
      newSelectedDate = { ...selectedDate, [name]: value };
    }
    
    setSelectedDate(newSelectedDate);
    updateParentForm(newSelectedDate);
  };

  // Update local state when parent form data changes
  useEffect(() => {
    setSelectedDate(getDateParts(formData.incidentDate));
  }, [formData.incidentDate]);

  const getSelectClass = (isDisabled) => `
    w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200
    ${isDisabled ? 'bg-gray-100 cursor-not-allowed opacity-50' : ''}
  `;

  return (
    <div className="flex gap-4">
      <div className="flex-1">
        <select
          name="year"
          value={selectedDate.year}
          onChange={handleDateChange}
          className={getSelectClass(false)}
          required
        >
          <option value="">Year</option>
          {years.map(year => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>

      <div className="flex-1">
        <select
          name="month"
          value={selectedDate.month}
          onChange={handleDateChange}
          disabled={!selectedDate.year}
          className={getSelectClass(!selectedDate.year)}
          required
        >
          <option value="">Month</option>
          {months.map(month => (
            <option key={month.value} value={month.value}>
              {month.label}
            </option>
          ))}
        </select>
      </div>

      <div className="flex-1">
        <select
          name="day"
          value={selectedDate.day}
          onChange={handleDateChange}
          disabled={!selectedDate.year || !selectedDate.month}
          className={getSelectClass(!selectedDate.year || !selectedDate.month)}
          required
        >
          <option value="">Day</option>
          {days.map(day => (
            <option key={day} value={day}>
              {day}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default DateDropdownSelector;
