import React, { useState } from "react";

import Navigation from "../components/Navigation";
import Cases from "./Cases";
import CaseForm from "./CaseForm";

const Home = () => {
  const intro = `Welcome to the Check My Charts patient portal.
                To see the details of a case, review evidence for that case,
                or create a new analysis of that case, click "View Case". To create a new
                case, fill out the form at the bottom of this page.`;
  return (
    <div className="container mx-auto px-4 max-w-4xl">
      <div className="bg-white shadow rounded-lg mt-8 mb-8 p-6">{intro}</div>
      <Cases />
      <CaseForm />
    </div>
  );
};

export default Home;
