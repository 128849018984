import React, { useState, useEffect } from 'react';
import { patientFlowAPI } from '../../services/api';

const Results = ({ caseId }) => {
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const caseResults = await patientFlowAPI.getCaseResults(caseId);
        setResults(caseResults);
      } catch (error) {
        console.error('Error fetching results:', error);
        alert('Failed to fetch results. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, [caseId]);

  const handleDownload = () => {
    // Implement download logic
    console.log('Downloading results...');
  };

  const handleReferral = (wantsReferral) => {
    // Implement referral logic
    console.log('User wants referral:', wantsReferral);
  };

  if (loading) {
    return <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white flex items-center justify-center">
      <div className="text-2xl text-gray-600">Loading results...</div>
    </div>;
  }

  if (!results) {
    return <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white flex items-center justify-center">
      <div className="text-2xl text-red-500">No results found.</div>
    </div>;
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white py-12">
      <div className="container mx-auto px-4 max-w-3xl">
        <div className="bg-white shadow-md rounded-lg p-8">
          <h1 className="text-4xl font-bold mb-6 text-gray-800">Your Case Results</h1>
          
          <button onClick={handleDownload} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full mb-8">
            Download Full Results
          </button>

          <div className="mb-8 p-6 bg-gray-100 rounded-lg">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">Case Summary</h2>
            <p className="mb-2"><strong>Name:</strong> {results.first_name} {results.last_name}</p>
            <p className="mb-2"><strong>Condition:</strong> {results.condition}</p>
            <p className="mb-2"><strong>Incident Date:</strong> {results.incident_date}</p>
            <p className="mb-2"><strong>Impact:</strong> {results.impact}</p>
          </div>

          <div className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">Our Assessment</h2>
            {results.status === 'malpractice_detected' ? (
              <p className="text-red-600 bg-red-100 p-4 rounded-lg">We have detected potential signs of malpractice in your case. We recommend you consult a legal professional. Patients can be owed from $300k to $10 million, depending on the state.</p>
            ) : (
              <p className="text-green-600 bg-green-100 p-4 rounded-lg">No obvious signs of errors were found in your case. However, you may still want to consult a legal professional for peace of mind.</p>
            )}
          </div>

          <div className="mb-8">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">Uploaded Files</h2>
            <ul className="list-disc pl-5 space-y-2">
              {results.files && results.files.map((file, index) => (
                <li key={index} className="text-gray-600">{file.filename}</li>
              ))}
            </ul>
          </div>

          <div className="mt-8 bg-blue-50 p-6 rounded-lg">
            <p className="mb-4 font-bold text-xl text-gray-800">Would you like us to connect you with a medical malpractice attorney?</p>
            <div className="space-x-4">
              <button onClick={() => handleReferral(true)} className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-6 rounded-full transition duration-300">Yes</button>
              <button onClick={() => handleReferral(false)} className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-6 rounded-full transition duration-300">No</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Results;
