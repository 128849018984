import React, { useState, useEffect } from "react";
import { addAnalysis } from "../../services/api";

const AnalysisForm = ({
  caseId,
  setAnalyses,
  analysisTypes,
  selectedFiles,
}) => {
  const [selectedType, setSelectedType] = useState("");
  const [parameterValue, setParameterValue] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    // Reset error when selected type changes
    setError("");
  }, [selectedType]);

  const handleTypeChange = (e) => {
    setSelectedType(parseInt(e.target.value));
  };

  const handleParameterChange = (value) => {
    setParameterValue(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (selectedFiles.length === 0) {
      setError("Must select files to ask for evidence from");
      return;
    }

    if (!selectedType) {
      setError("Please select an evidence type");
      return;
    }

    if (!parameterValue.trim()) {
      setError("Please enter a condition or symptom of interest");
      return;
    }

    try {
      const selectedAnalysisType = analysisTypes.find(
        (type) => type.id === selectedType,
      );
      const paramName = selectedAnalysisType
        ? Object.keys(selectedAnalysisType.parameters)[0]
        : "condition_or_symptom";

      for (const file_id of selectedFiles) {
        const response = await addAnalysis({
          caseId,
          analysisType: selectedType,
          parameters: { [paramName]: parameterValue },
          file_id: file_id,
        });
        setAnalyses((prev) => [...prev, response]);
      }
      alert("Analysis added successfully");
      setSelectedType("");
      setParameterValue("");
    } catch (error) {
      setError(error.message);
    }
  };

  const selectedAnalysisType = analysisTypes.find(
    (type) => type.id === selectedType,
  );

  const paramName = selectedAnalysisType
    ? Object.keys(selectedAnalysisType.parameters)[0]
    : "condition_or_symptom";

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor={paramName}
        >
          {selectedAnalysisType
            ? Object.keys(selectedAnalysisType.parameters)[0]
            : "Condition or Symptom of Interest"}
        </label>
        <input
          id={paramName}
          type="text"
          value={parameterValue}
          onChange={(e) => handleParameterChange(e.target.value)}
          placeholder="Enter condition or symptom of interest"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div>
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="analysisType"
        >
          Evidence Type
        </label>
        <select
          id="analysisType"
          value={selectedType}
          onChange={handleTypeChange}
          className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        >
          <option value="">Select Evidence Type</option>
          {analysisTypes.map((type) => (
            <option key={type.id} value={type.id}>
              {type.display_name}
            </option>
          ))}
        </select>
      </div>

      {error && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <span className="block sm:inline">{error}</span>
        </div>
      )}

      <div className="flex justify-center">
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Ask AI
        </button>
      </div>
    </form>
  );
};

export default AnalysisForm;
