import React, { useState, useCallback } from "react";
import AnalysisForm from "./AnalysisForm";
import { FilesSection } from "./FilesSection";
import { downloadFile, deleteFile } from "../../services/api";
import GreenInfoIcon from "../../components/GreenInfoIcon";

export const AnalysisSection = ({
  caseId,
  setAnalyses,
  analysisTypes,
  refreshCaseDetails,
  files,
}) => {
  const hasFiles = files.length > 0;
  const intro = `Upload and select the files you want to search for evidence.`;

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [deleteError, setDeleteError] = useState(null);
  const [uploadError, setUploadError] = useState(null);

  const handleFileSelect = (fileId) => {
    setSelectedFiles((prev) =>
      prev.includes(fileId)
        ? prev.filter((id) => id !== fileId)
        : [...prev, fileId],
    );
  };

  const handleUploadSuccess = useCallback(() => {
    refreshCaseDetails();
    setSelectedFiles([]);
  }, [refreshCaseDetails]);

  const handleUploadError = useCallback(
    (errorMessage) => {
      setUploadError(errorMessage);
    },
    [refreshCaseDetails],
  );

  const handleDownload = async (fileId) => {
    try {
      await downloadFile(fileId);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleDelete = async (fileId) => {
    if (window.confirm("Are you sure you want to delete this file?")) {
      try {
        await deleteFile(fileId);
        setSelectedFiles((prev) => prev.filter((id) => id !== fileId));
        refreshCaseDetails();
        setDeleteError(null);
      } catch (error) {
        console.error("Error deleting file:", error);
        setDeleteError(error.message);
      }
    }
  };

  return (
    <section className="mb-8">
      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-2xl font-bold mb-4">Ask AI for Evidence</h2>
        {files.length > 0 && (
          <div className="bg-white pb-6 flex items-center">
            <GreenInfoIcon size={28} />
            <span className="ml-3">{intro}</span>
          </div>
        )}

        {deleteError && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
            role="alert"
          >
            <strong className="font-bold">Error: </strong>
            <span className="block sm:inline">{deleteError}</span>
          </div>
        )}
        {uploadError && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
            role="alert"
          >
            <strong className="font-bold">Error: </strong>
            <span className="block sm:inline">{uploadError}</span>
          </div>
        )}
        <FilesSection
          files={files}
          selectedFiles={selectedFiles}
          handleFileSelect={handleFileSelect}
          handleDownload={handleDownload}
          handleDelete={handleDelete}
          onUploadSuccess={handleUploadSuccess}
          onUploadError={handleUploadError}
          case_id={caseId}
        />

        {hasFiles ? (
          <AnalysisForm
            caseId={caseId}
            setAnalyses={setAnalyses}
            analysisTypes={analysisTypes}
            selectedFiles={selectedFiles}
          />
        ) : (
          <p className="text-gray-600">
            You must upload files to ask for evidence from them.
          </p>
        )}
      </div>
    </section>
  );
};
