import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { patientFlowAPI, trackEvent } from '../../services/api';
import DateDropdownSelector from '../../components/DateDropdownSelector'; 
import { AlertTriangle, Calendar, MapPin, FileText, Clock, ActivitySquare } from 'lucide-react';

// Custom Alert component
const Alert = ({ children, variant = 'default' }) => {
  const bgColor = variant === 'destructive' ? 'bg-red-100' : 'bg-blue-100';
  const textColor = variant === 'destructive' ? 'text-red-800' : 'text-blue-800';
  
  return (
    <div className={`${bgColor} ${textColor} p-4 rounded-md mb-4`}>
      {children}
    </div>
  );
};

const Step2CaseDetails = ({ caseId, onComplete }) => {
  const [localCaseId, setLocalCaseId] = useState(caseId);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    birthday: null,
    state: '',
    condition: '',
    incidentDate: '',
    impact: '',
  });
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!localCaseId) {
      const storedCaseId = localStorage.getItem('caseId');
      if (storedCaseId) {
        setLocalCaseId(storedCaseId);
      } else {
        console.error('No case ID found in props or localStorage');
        navigate('/step1');
      }
    }
  }, [caseId, localCaseId, navigate]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsSubmitting(true);
    
    trackEvent('SubmitApplication', '/step2')
        .catch(error => console.error('Error tracking ApplicationSubmission:', error));

    try {
      await patientFlowAPI.updateCaseDetails(localCaseId, formData);
      onComplete();
    } catch (error) {
      console.error('Error updating case details:', error);
      setError('Failed to update case details. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!localCaseId) {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-100 to-white py-12">
      <div className="container mx-auto px-4 max-w-3xl">
        <h1 className="text-4xl font-bold mb-6 text-gray-800 text-center">Step 2: Case Details</h1>
        <div className="bg-white shadow-lg rounded-xl p-8 mb-8">
          <Alert>
            <div className="flex items-center">
              <AlertTriangle className="h-5 w-5 mr-2 text-blue-500" />
              <span className="font-semibold">Why these details matter</span>
            </div>
            <p className="mt-2">
              Providing accurate information helps us understand your case better and increases the chances of identifying potential medical errors.
            </p>
          </Alert>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="condition" className="block text-gray-700 text-sm font-semibold mb-2 flex items-center">
                <FileText className="mr-2 h-5 w-5 text-blue-500" />
                What do you think went wrong with your care? How did it affect you?
              </label>
              <textarea
                id="condition"
                name="condition"
                value={formData.condition}
                onChange={handleChange}
                required
                rows="3"
                placeholder="Tell us what you're dealing with, what you think might have gone wrong, and how it impacted you"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
              ></textarea>
            </div>
            <div>
              <label htmlFor="state" className="block text-gray-700 text-sm font-semibold mb-2 flex items-center">
                <MapPin className="mr-2 h-5 w-5 text-blue-500" />
                Where did it happen (city, state)? 
              </label>
              <input
                type="text"
                id="state"
                name="state"
                value={formData.state}
                onChange={handleChange}
                required
                placeholder="Enter the city and state where it happened"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
              />
            </div>
            <div>
              <label htmlFor="incidentDate" className="block text-gray-700 text-sm font-semibold mb-2 flex items-center">
                <Clock className="mr-2 h-5 w-5 text-blue-500" />
                Around when did it happen?
              </label>
              <DateDropdownSelector 
              formData={formData} 
              handleChange={handleChange} 
              /> 
            </div>
            {error && (
              <Alert variant="destructive">
                <div className="flex items-center">
                  <AlertTriangle className="h-5 w-5 mr-2" />
                  <span className="font-semibold">Error</span>
                </div>
                <p className="mt-2">{error}</p>
              </Alert>
            )}
            <button 
              type="submit" 
              className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-4 rounded-full transition duration-300 ease-in-out flex items-center justify-center"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Next Step'}
              <svg className="ml-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </button>
          </form>
        </div>
        <div className="text-center text-gray-600 text-sm flex items-center justify-center">
          <svg className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
          </svg>
          Your information is secure and will only be used for case review purposes.
        </div>
      </div>
    </div>
  );
};

export default Step2CaseDetails;
