import React, { useState, useMemo } from 'react';
import { AlertCircle, ChevronDown, ChevronUp, Circle, Search } from 'lucide-react';
import CollapsiblePDFDisplay from "../../components/CollapsiblePDFDisplay";

const Alert = ({ children, className, ...props }) => (
  <div className={`p-4 rounded-md ${className}`} {...props}>
    {children}
  </div>
);

const AlertTitle = ({ children, className, ...props }) => (
  <h5 className={`font-medium ${className}`} {...props}>
    {children}
  </h5>
);

const EventCard = ({ event, pdfUrl, pagesPerChunk, searchTerm }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="bg-white p-4 rounded-lg shadow-md mb-4" data-conv-ids={event.conv_ids.join(',')} data-chunk-ids={event.chunk_ids.join(',')}>
      <div className="flex justify-between items-center mb-2">
        <h3 className="text-lg font-semibold">{event.event_type}</h3>
        <span className="text-sm text-gray-500">{event.date}</span>
      </div>
      <p className="text-gray-700 mb-2">{event.description}</p>
      <div className="text-sm text-gray-600 mb-2">
        <strong>Relevance:</strong> {event.relevance_score}/5
      </div>
      <div className="text-sm text-gray-600 mb-2">
        <strong>Relevance Explanation:</strong> {event.relevance_explanation}
      </div>
      <CollapsiblePDFDisplay
        chunkIds={event.chunk_ids}
        pdfUrl={pdfUrl}
        pagesPerChunk={pagesPerChunk}
        searchTerm={searchTerm}
        alertTerms={event.excerpt}
      />
    </div>
  );
};

const MedicalTimelineDisplay = ({ content, pdfUrl, pagesPerChunk }) => {
  const [selectedEventType, setSelectedEventType] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const [minRelevance, setMinRelevance] = useState(1);

  const events = useMemo(() => content.events, [content]);

  const eventTypes = useMemo(() => {
    const types = new Set(events.map(event => event.event_type));
    return ['All', ...Array.from(types)];
  }, [events]);

  const filteredEvents = useMemo(() => {
    return events.filter(event => {
      const matchesType = selectedEventType === 'All' || event.event_type === selectedEventType;
      const matchesSearch = !searchTerm || 
        JSON.stringify(event).toLowerCase().includes(searchTerm.toLowerCase());
      const matchesRelevance = event.relevance_score >= minRelevance;
      return matchesType && matchesSearch && matchesRelevance;
    });
  }, [events, selectedEventType, searchTerm, minRelevance]);

  const sortedEvents = useMemo(() => {
    return filteredEvents.sort((a, b) => new Date(a.date) - new Date(b.date));
  }, [filteredEvents]);

  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-2xl font-bold mb-6">Medical Event Timeline</h2>
      <div className="flex space-x-4 mb-6">
        <div className="flex-1">
          <label htmlFor="event-type-filter" className="block text-sm font-medium text-gray-700 mb-1">
            Filter by Event Type:
          </label>
          <select
            id="event-type-filter"
            value={selectedEventType}
            onChange={(e) => setSelectedEventType(e.target.value)}
            className="w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
          >
            {eventTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>
        <div className="flex-1 relative">
          <label htmlFor="search-filter" className="block text-sm font-medium text-gray-700 mb-1">
            Filter by text in section:
          </label>
          <input
            id="search-filter"
            type="text"
            placeholder="Filter for text..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full px-4 py-2 pl-10 pr-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <Search className="absolute left-3 top-9 text-gray-400" size={20} />
        </div>
        <div className="flex-1">
          <label htmlFor="relevance-filter" className="block text-sm font-medium text-gray-700 mb-1">
            Minimum Relevance:
          </label>
          <select
            id="relevance-filter"
            value={minRelevance}
            onChange={(e) => setMinRelevance(Number(e.target.value))}
            className="w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
          >
            {[1, 2, 3, 4, 5].map((relevance) => (
              <option key={relevance} value={relevance}>
                {relevance}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="relative">
        <div className="absolute left-4 top-0 bottom-0 w-0.5 bg-gray-200"></div>
        {sortedEvents.map((event, index) => (
          <div key={index} className="relative pl-8 pb-8">
            <Circle className="absolute left-0 top-2 text-blue-500 bg-white" size={24} />
            <EventCard 
              event={event} 
              pdfUrl={pdfUrl} 
              pagesPerChunk={pagesPerChunk}
              searchTerm={searchTerm}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MedicalTimelineDisplay;
