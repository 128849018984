import React from "react";
import SharedAnalysisDisplay, { ChunkContent } from "./SharedAnalysisDisplay";

const ExplicitPreexistingCondsDisplay = ({
  content,
  pdfUrl,
  pagesPerChunk,
}) => {
  const renderContent = (diagnosis, data, index) => {
    if (index === undefined) {
      // Render summary content
      return (
        <ChunkContent>
          <p className="text-gray-700 mt-1 mb-2">
            This condition was found in {data.chunk_ids.length} section(s).
          </p>
          <h4 className="font-medium text-gray-700 mb-2">Related Symptoms:</h4>
          {data.symptoms.length > 0 ? (
            <ul className="list-disc list-inside text-gray-600">
              {data.symptoms.map((symptom, idx) => (
                <li key={idx}>{symptom}</li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-500">
              No similar symptoms found for this diagnosis.
            </p>
          )}
        </ChunkContent>
      );
    }

    // Render chunk-specific content
    return (
      <ChunkContent>
        <h4 className="font-medium text-gray-700 mb-2">Section {index + 1}</h4>
        {/* Add any chunk-specific content here if needed */}
      </ChunkContent>
    );
  };

  return (
    <SharedAnalysisDisplay
      content={content}
      pdfUrl={pdfUrl}
      pagesPerChunk={pagesPerChunk}
      alertClassName="bg-green-50 border border-green-200"
      titleClassName="text-green-800"
      renderContent={renderContent}
    />
  );
};

export default ExplicitPreexistingCondsDisplay;
