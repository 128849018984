import React, { useState, useMemo } from 'react';
import { Calendar, Search, ChevronDown, ChevronUp, Download } from 'lucide-react';
import CollapsiblePDFDisplay from "../../components/CollapsiblePDFDisplay";
import { downloadFile } from "../../services/api"; 

const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  
  try {
    // First normalize the date string to handle edge cases
    const normalizedDate = normalizeDate(dateString);
    
    // Split into date and time components
    const [datePart, timePart = '00:00:00'] = normalizedDate.split(' ');
    const [year, month, day] = datePart.split('-');
    
    // Convert month number to name
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const monthName = monthNames[parseInt(month) - 1];
    
    // Handle special cases
    if (day === '00') {
      // If day is 00, return just the month and year
      return `${monthName} ${year}`;
    }
    
    if (timePart === '00:00:00') {
      // If time is 00:00:00, return just the date without time
      return `${monthName} ${parseInt(day)}${getDaySuffix(parseInt(day))}, ${year}`;
    }
    
    // For cases with specific time, parse and format with full date and time
    const localDate = new Date(`${year}-${month}-${day}T${timePart}`);
    if (isNaN(localDate.getTime())) {
      return 'N/A';
    }
    
    return localDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'N/A';
  }
};

// Helper function to add appropriate suffix to day
const getDaySuffix = (day) => {
  if (day >= 11 && day <= 13) return 'th';
  const lastDigit = day % 10;
  switch (lastDigit) {
    case 1: return 'st';
    case 2: return 'nd';
    case 3: return 'rd';
    default: return 'th';
  }
};



// Function to normalize dates for comparison
const normalizeDate = (dateStr) => {
  if (!dateStr) return '';
  
  try {
    // Split date and time if present
    const [date, time = ''] = dateStr.split(' ');
    
    // Split date parts
    const [year, month, day = ''] = date.split('-');
    
    // Ensure month and day are valid
    const normalizedMonth = month === '00' ? '01' : month;
    const normalizedDay = day === '00' || !day ? '01' : day;
    
    // Reconstruct the normalized date
    const normalizedDate = `${year}-${normalizedMonth}-${normalizedDay}`;
    
    // Add back time if it exists
    return time ? `${normalizedDate} ${time}` : normalizedDate;
  } catch (error) {
    console.error('Error normalizing date:', error);
    return '';
  }
};

const ExplanationSection = ({ title, content, isComplaint = false, allEvents, setViewMode }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  const processExplanation = (text) => {
    if (!text) return [];
    
    const parts = text.split(/(\[\d+(?:\s*,\s*\d+)*\])/g);
    
    return parts.map((part, index) => {
      const match = part.match(/\[(\d+(?:\s*,\s*\d+)*)\]/);
      if (match) {
        const eventIndices = match[1].split(',').map(num => parseInt(num.trim()));
        return (
          <span key={index} className="inline-flex gap-1">
            {'['}
            {eventIndices.map((eventIndex, i) => (
              <React.Fragment key={eventIndex}>
                <a
                  href={`#event-${eventIndex}`}
                  className="text-blue-600 hover:text-blue-800 hover:underline"
                  onClick={(e) => {
                    e.preventDefault();
                    if (setViewMode) {
                      setViewMode('all');
                      setTimeout(() => {
                        document.getElementById(`event-${eventIndex}`)?.scrollIntoView({ behavior: 'smooth' });
                      }, 100);
                    }
                  }}
                >
                  {eventIndex}
                </a>
                {i < eventIndices.length - 1 && ', '}
              </React.Fragment>
            ))}
            {']'}
          </span>
        );
      }
      return <span key={index}>{part}</span>;
    });
  };

  const formatComplaintText = (text) => {
    if (!text) return null;
    
    // Clean up the text by replacing \n with actual newlines
    const cleanedText = text.replace(/\\n/g, '\n');

    return (
      <div className="text-center whitespace-pre-wrap">
        {cleanedText}
      </div>
    );
  };

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden">
      <div className="flex justify-between items-center">
        <button 
          className="flex-grow p-6 flex justify-between items-center text-left hover:bg-gray-50"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <h2 className="text-lg font-semibold text-gray-900">{title}</h2>
          {isExpanded ? (
            <ChevronUp className="h-5 w-5 text-gray-500" />
          ) : (
            <ChevronDown className="h-5 w-5 text-gray-500" />
          )}
        </button>
      </div>
      {isExpanded && (
        <div className="px-6 pb-6">
          <div className="prose max-w-none">
            {isComplaint ? (
              <div className="font-serif text-gray-900 leading-relaxed">
                {formatComplaintText(content)}
              </div>
            ) : (
              processExplanation(content)
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const EventCard = ({ event, displayNumber, pdfUrl, pagesPerChunk }) => {
  return (
    <div 
      id={`event-${displayNumber}`}
      className="bg-white rounded-lg shadow-sm border border-gray-100 px-6 pb-1 pt-4 hover:border-blue-100 transition-colors scroll-mt-24" 
      data-conv-id={event.conv_id} 
      data-chunk-id={event.chunk_id}
    >
      <div className="flex items-start gap-4">
        <div className="flex-shrink-0">
          <div className="w-12 h-12 rounded-full bg-blue-50 flex items-center justify-center">
            <span className="text-blue-600 font-semibold">#{displayNumber}</span>
          </div>
        </div>
        <div className="flex-grow">
          <div className="flex justify-between items-center mb-1">
            <div className="text-sm font-medium text-blue-600">
              {formatDate(event.date)}
            </div>
            <div className="text-sm text-gray-600">
              {event.provider}
            </div>
          </div>
          <div className="text-gray-900 mb-3">{event.narrative_description}</div>
          <CollapsiblePDFDisplay
            chunkIds={[event.chunk_id]}
            pdfUrl={event.pdfUrl || pdfUrl}
            pagesPerChunk={pagesPerChunk}
            alertTerms={event.excerpt}
          />
        </div>
      </div>
    </div>
  );
};

const EventList = ({ events, pdfUrl, pagesPerChunk, allEvents }) => {
  // Create a map of event references by comparing relevant fields
const eventToIndexMap = useMemo(() => {
  const map = new Map();
  allEvents.forEach((event, index) => {
    const key = `${event.date}-${event.narrative_description}-${event.provider}-${event.chunk_id}`;
    if (!map.has(key)) {
      map.set(key, index);
    }
  });
  return map;
}, [allEvents]);
const getEventKey = (event) => {
  return `${event.date}-${event.narrative_description}-${event.provider}-${event.chunk_id}`;
};
const sortedEvents = useMemo(() => {
  // First remove duplicates
  const uniqueEvents = Array.from(
    new Map(events.map(event => [getEventKey(event), event])).values()
  );
  
  // Then sort the unique events
  return uniqueEvents.sort((a, b) => {
    if (!a.date || a.date === 'N/A') return 1;
    if (!b.date || b.date === 'N/A') return -1;

    const dateA = new Date(normalizeDate(a.date));
    const dateB = new Date(normalizeDate(b.date));

    if (isNaN(dateA)) return 1;
    if (isNaN(dateB)) return -1;

    return dateA - dateB;
  });
}, [events]);

  return (
    <div className="space-y-4">
      {sortedEvents.map((event) => {
        const originalIndex = eventToIndexMap.get(getEventKey(event));
        return (
          <div key={getEventKey(event)} className="relative">
            <div className="absolute left-6 top-0 bottom-0 w-px bg-blue-100 -z-10" />
            <EventCard 
              event={event} 
              displayNumber={originalIndex}
              pdfUrl={pdfUrl} 
              pagesPerChunk={pagesPerChunk}
            />
          </div>
        );
      })}
    </div>
  );
};

const NarrativeChronologyDisplay = ({ content, pdfUrl, pagesPerChunk, analysisId, analysis }) => {
  const [viewMode, setViewMode] = useState('key');
  const [searchTerm, setSearchTerm] = useState('');

  const allEvents = useMemo(() => content?.all_events || [], [content]);

  const events = useMemo(() => {
    if (!content) return [];
    
    let sourceEvents;
    switch (viewMode) {
      case 'key':
        sourceEvents = content.key_events;
        break;
      case 'subject':
        sourceEvents = content.subject_events;
        break;
      case 'all':
        sourceEvents = content.all_events;
        break;
      default:
        sourceEvents = [];
    }
    
    if (!sourceEvents) return [];
    
    return searchTerm ? sourceEvents.filter(event => {
      const description = String(event.narrative_description || '');
      const excerpts = Array.isArray(event.excerpt) ? event.excerpt : [];
      const term = searchTerm.toLowerCase();
      
      return description.toLowerCase().includes(term) || 
             excerpts.some(excerpt => excerpt.toLowerCase().includes(term));
    }) : sourceEvents;
  }, [content, viewMode, searchTerm]);

  if (!content || !content.all_events) {
    return (
      <div className="p-8 text-center text-gray-500 bg-gray-50 rounded-lg">
        No event data available
      </div>
    );
  }
  
  const handleReportDownload = async () => {
    try {
      if (!analysis?.report_path) {
        throw new Error('No report available for this analysis');
      }
      await downloadFile(analysis.report_path);
    } catch (error) {
      console.error('Error downloading report:', error);
      alert('Error downloading report. Please try again later.');
    }
  };


    const DownloadBar = ({ onDownload }) => {
      return (
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-4 mb-8">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-3">
              <div className="h-10 w-10 rounded-full bg-blue-50 flex items-center justify-center">
                <Download className="h-5 w-5 text-blue-600" />
              </div>
              <div>
                <h3 className="font-medium text-gray-900">Malpractice Report</h3>
                <p className="text-sm text-gray-500">Download the complete analysis</p>
              </div>
            </div>
            <button
              onClick={onDownload}
              className="inline-flex items-center gap-2 px-4 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              <Download className="h-4 w-4" />
              <span>Download Report</span>
            </button>
          </div>
        </div>
      );
    };


  return (
    <div className="max-w-4xl mx-auto p-6 space-y-8">
    <DownloadBar onDownload={handleReportDownload} />
    <div className="space-y-4">
        <ExplanationSection 
          title="Evidence of Malpractice or Negligence" 
          content={content.case_explanation} 
          allEvents={allEvents}
          setViewMode={setViewMode}
        />
        <ExplanationSection 
          title="Potential Pre-existing Conditions" 
          content={content.pre_existing_explanation} 
          allEvents={allEvents}
          setViewMode={setViewMode}
        />
        <ExplanationSection 
          title="Draft of Formal Complaint" 
          content={content.complaint}
          isComplaint={true}
          allEvents={allEvents}
          setViewMode={setViewMode}
        />
     </div>

      <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
        <h1 className="text-2xl font-bold text-gray-900 mb-6">Medical Event Timeline</h1>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              View Mode
            </label>
            <select
              value={viewMode}
              onChange={(e) => setViewMode(e.target.value)}
              className="w-full rounded-lg border-gray-200 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            >
              <option value="key">Key Events</option>
              <option value="subject">Subject Events</option>
              <option value="all">All Events</option>
            </select>
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Search Events
            </label>
            <div className="relative">
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search in events..."
                className="w-full rounded-lg border-gray-200 shadow-sm focus:border-blue-500 focus:ring-blue-500 pl-10"
              />
              <Search className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 -translate-y-1/2" />
            </div>
          </div>
        </div>

        <div className="bg-blue-50 rounded-lg p-4">
          <div className="flex items-center gap-2 text-sm text-blue-700">
            <span className="font-medium">
              Showing {events.length} {viewMode === 'key' ? 'key' : viewMode === 'subject' ? 'subject' : ''} events
              {searchTerm && ` matching "${searchTerm}"`}
            </span>
          </div>
        </div>
      </div>

      <EventList 
        events={events} 
        pdfUrl={pdfUrl} 
        pagesPerChunk={pagesPerChunk}
        allEvents={allEvents}
      />
    </div>
  );
};

export default NarrativeChronologyDisplay;
