// useHighlightTerms.js
import { useState, useCallback, useEffect } from 'react';

const useHighlightTerms = (searchTerm, alertTerms) => {
  const [highlights, setHighlights] = useState([]);

  const calculateHighlights = useCallback(() => {
  const newHighlights = [];
  const textLayers = document.querySelectorAll('.react-pdf__Page__textContent');

  const createPattern = (term) => {
    const words = term.replace(/['"]/g, '').split(/\s+/);
    if (words.length > 1) {
      return {
        regex: new RegExp(words.map(word => `(?:${word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`).join('[\\s\\S]{0,20}'), 'gi'),
        style: 'fill: yellow; fill-opacity: 0.5;'
      };
    } else if (words[0].length > 3) {
      return {
        regex: new RegExp(`\\b${words[0].replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}\\b`, 'gi'),
        style: 'fill: yellow; fill-opacity: 0.5;'
      };
    }
    return null;
  };

  const patterns = [];
  if (searchTerm) {
    const searchPattern = createPattern(searchTerm);
    if (searchPattern) patterns.push(searchPattern);
  }
  if (alertTerms && alertTerms.length > 0) {
    alertTerms.forEach(term => {
      const alertPattern = createPattern(term);
      if (alertPattern) {
        alertPattern.style = 'fill: red; fill-opacity: 0.5;';
        patterns.push(alertPattern);
      }
    });
  }

  textLayers.forEach((textLayer, pageIndex) => {
    const textItems = Array.from(textLayer.querySelectorAll('span'));
    const fullText = textItems.map(span => span.textContent).join(' ');

    patterns.forEach(({ regex, style }) => {
      let match;
      while ((match = regex.exec(fullText)) !== null) {
        newHighlights.push({
          pageIndex,
          startIndex: match.index,
          endIndex: match.index + match[0].length,
          style
        });
      }
    });
  });

  setHighlights(newHighlights);
}, [searchTerm, alertTerms]);

  const applyHighlights = useCallback(() => {
    const textLayers = document.querySelectorAll('.react-pdf__Page__textContent');
    
    const applyHighlightsToLayer = (index) => {
      if (index >= textLayers.length) return;

      const textLayer = textLayers[index];
      textLayer.querySelectorAll('.highlight-overlay').forEach(el => el.remove());

      const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      svg.setAttribute('class', 'highlight-overlay');
      svg.style.position = 'absolute';
      svg.style.top = '0';
      svg.style.left = '0';
      svg.style.width = '100%';
      svg.style.height = '100%';
      svg.style.pointerEvents = 'none';

      const textItems = Array.from(textLayer.querySelectorAll('span'));
      const layerRect = textLayer.getBoundingClientRect();

      highlights
        .filter(h => h.pageIndex === index)
        .forEach(({ startIndex, endIndex, style }) => {
          let currentIndex = 0;
          textItems.forEach((span) => {
            const spanText = span.textContent;
            const spanLength = spanText.length;

            if (currentIndex + spanLength > startIndex && currentIndex < endIndex) {
              const spanRect = span.getBoundingClientRect();
              const highlightStart = Math.max(0, startIndex - currentIndex);
              const highlightEnd = Math.min(spanLength, endIndex - currentIndex);
              
              const rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
              rect.setAttribute('x', `${spanRect.left - layerRect.left + (highlightStart / spanLength) * spanRect.width}`);
              rect.setAttribute('y', `${spanRect.top - layerRect.top}`);
              rect.setAttribute('width', `${((highlightEnd - highlightStart) / spanLength) * spanRect.width}`);
              rect.setAttribute('height', `${spanRect.height}`);
              rect.setAttribute('style', style);

              svg.appendChild(rect);
            }

            currentIndex += spanLength + 1;
          });
        });

      textLayer.appendChild(svg);

      requestAnimationFrame(() => applyHighlightsToLayer(index + 1));
    };

    applyHighlightsToLayer(0);
  }, [highlights]);

  useEffect(() => {
    calculateHighlights();
  }, [calculateHighlights]);

  useEffect(() => {
    applyHighlights();
  }, [applyHighlights]);

  return { calculateHighlights, applyHighlights };
};

export default useHighlightTerms;
