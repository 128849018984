import React from "react";

const GreenInfoIcon = ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="12" fill="#10B981" />
    <rect x="11" y="11" width="2" height="7" fill="white" />
    <rect x="11" y="6" width="2" height="2" fill="white" />
  </svg>
);

export default GreenInfoIcon;
