import React, { useState } from "react";
import UploadForm from "../../components/UploadForm";
import { Download, Trash2, Plus } from "lucide-react";

export const FilesSection = ({
  files,
  selectedFiles,
  handleFileSelect,
  handleDownload,
  handleDelete,
  onUploadSuccess,
  onUploadError,
  case_id,
}) => {
  const [showUploadForm, setShowUploadForm] = useState(files.length === 0);

  return (
    <section className="mb-8">
      <div className="bg-white shadow p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Files</h2>
          {!showUploadForm && (
            <button
              onClick={() => setShowUploadForm(true)}
              className="bg-gray-100 hover:bg-gray-200 text-gray-700 font-medium py-2 px-4 rounded inline-flex items-center transition-colors duration-200"
            >
              <Plus size={18} className="mr-1" />
              Add Files
            </button>
          )}
          {showUploadForm && (
            <button
              onClick={() => setShowUploadForm(false)}
              className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-medium py-2 px-4 rounded"
            >
              Cancel
            </button>
          )}
        </div>
        {showUploadForm && (
          <div className="mb-4">
            <UploadForm
              onUploadSuccess={() => {
                onUploadSuccess();
                setShowUploadForm(false);
              }}
              onUploadError={onUploadError}
              case_id={case_id}
            />
          </div>
        )}

        {files.length > 0 ? (
          <ul className="space-y-2">
            {files.map((file) => (
              <li
                key={file.id}
                className="flex items-center justify-between py-2 flex-wrap"
              >
                <div className="flex items-center flex-grow mr-2 min-w-0">
                  <input
                    type="checkbox"
                    checked={selectedFiles.includes(file.id)}
                    onChange={() => handleFileSelect(file.id)}
                    className="mr-3 w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 cursor-pointer"
                  />
                  <span className="truncate">{file.filename}</span>
                </div>
                <div className="flex shrink-0 space-x-2">
                  <button
                    onClick={() => handleDownload(file.id)}
                    className="text-blue-500 hover:text-blue-700 p-1 rounded"
                    aria-label="Download"
                  >
                    <Download size={20} />
                  </button>
                  <button
                    onClick={() => handleDelete(file.id)}
                    className="text-red-500 hover:text-red-700 p-1 rounded"
                    aria-label="Delete"
                  >
                    <Trash2 size={20} />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-500 text-center">No files uploaded yet.</p>
        )}
      </div>
    </section>
  );
};
