import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import LandingPage from './LandingPage';
import Step1PersonalInfo from './Step1PersonalInfo';
import Step2CaseDetails from './Step2CaseDetails';
import Step3RecordUpload from './Step3RecordUpload';
import CaseConfirmation from './CaseConfirmation';
import Results from './Results';

const PatientFlow = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [caseId, setCaseId] = useState(null);

  useEffect(() => {
    // Retrieve caseId from localStorage on component mount
    const storedCaseId = localStorage.getItem('caseId');
    if (storedCaseId) {
      setCaseId(storedCaseId);
    }
  }, []);

  useEffect(() => {
    // Save caseId to localStorage whenever it changes
    if (caseId) {
      localStorage.setItem('caseId', caseId);
    }
  }, [caseId]);

  const handleStep1Complete = (newCaseId) => {
    setCaseId(newCaseId);
    navigate('/step2');
  };

  const handleStep2Complete = () => {
    navigate('/step3');
  };

  const handleStep3Complete = () => {
    navigate('/confirmation');
  };

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/step1" element={<Step1PersonalInfo onComplete={handleStep1Complete} />} />
      <Route path="/step2" element={<Step2CaseDetails caseId={caseId} onComplete={handleStep2Complete} />} />
      <Route path="/step3" element={<Step3RecordUpload caseId={caseId} onComplete={handleStep3Complete} />} />
      <Route path="/confirmation" element={<CaseConfirmation caseId={caseId} />} />
      <Route path="/results" element={<Results caseId={caseId} />} />
    </Routes>
  );
};

export default PatientFlow;
