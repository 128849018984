import React from "react";
import SharedAnalysisDisplay, { ChunkContent } from "./SharedAnalysisDisplay";

const PreexistingSymptomsDisplay = ({ content, pdfUrl, pagesPerChunk }) => {
  const renderContent = (symptom, data, index) => {
    if (index === undefined) {
      // Render summary content
      return (
        <p className="text-gray-700">
          This similar symptom was found in {data.chunk_ids.length} section(s).
        </p>
      );
    }

    // Render chunk-specific content
    return (
      <ChunkContent>
        <h4 className="font-medium text-gray-700">Section {index + 1}</h4>
        {/* Add any chunk-specific content here if needed */}
      </ChunkContent>
    );
  };

  return (
    <SharedAnalysisDisplay
      content={content}
      pdfUrl={pdfUrl}
      pagesPerChunk={pagesPerChunk}
      alertClassName="bg-green-50 border border-green-200"
      titleClassName="text-green-800"
      renderContent={renderContent}
    />
  );
};

export default PreexistingSymptomsDisplay;
