import React from "react";
import SharedAnalysisDisplay, { ChunkContent } from "./SharedAnalysisDisplay";

const CarePlanFNDisplay = ({ content, pdfUrl, pagesPerChunk }) => {
  const renderContent = (condition, data, index) => {
    if (index === undefined) {
      // Render summary content
      return (
        <p className="text-gray-700">
          This condition was identified in {data.chunk_ids.length} section(s) of
          the record.
        </p>
      );
    }

    // Render chunk-specific content
    return (
      <ChunkContent>
        <h4 className="font-medium text-gray-700 mb-2">Section {index + 1}</h4>
        <h5 className="font-medium text-gray-700 mb-2">Explanation:</h5>
        <blockquote className="pl-4 border-l-4 border-gree-500 italic text-gray-600">
          "{data.explanations?.[index] || "No explanation provided"}"
        </blockquote>
        <h5 className="font-medium text-gray-700 mt-4 mb-2">
          Missing Care Options:
        </h5>
        {Array.isArray(data.missing_care_options?.[index]) &&
        data.missing_care_options[index].length > 0 ? (
          <ul className="list-disc list-inside text-gray-600">
            {data.missing_care_options[index].map((option, optionIndex) => (
              <li key={optionIndex}>{option}</li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-500">
            No missing care options for this section.
          </p>
        )}
      </ChunkContent>
    );
  };

  return (
    <SharedAnalysisDisplay
      content={content}
      pdfUrl={pdfUrl}
      pagesPerChunk={pagesPerChunk}
      alertClassName="bg-green-50 border border-green-200"
      titleClassName="text-green-800"
      renderContent={renderContent}
    />
  );
};

export default CarePlanFNDisplay;
